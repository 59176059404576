import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import { styled } from "@mui/material/styles";
import { useTranslation } from 'react-i18next';

const CustomTextField = styled(TextField)({
  "& label": {
    color: "#FFFFFF",
  },
  "& label.Mui-focused": {
    color: "#FFFFFF",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#FFFFFF",
      borderWidth: "2px",
    },
    "&:hover fieldset": {
      borderColor: "#FFFFFF",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FFFFFF",
    },
    color: "#FFFFFF",
  },
});

const ReservationForm = ({ onSubmit, responseMessage }) => {
  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({
    nombre: "",
    email: "",
    tel: "",
    message: "",
    acceptTerms: false,
    acceptOffers: false,
  });

  const [errorMessage, setErrorMessage] = useState(""); // Mensaje de error para los términos

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validación del checkbox obligatorio
    if (!formData.acceptTerms) {
      setErrorMessage("Debes aceptar el tratamiento de los datos para continuar.");
      return;
    }

    setErrorMessage(""); // Limpia el mensaje de error si todo está bien
    onSubmit(e, formData); // Llama a la función de envío con los datos
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        width: "100%",
        maxWidth: "500px",
        margin: "auto",
        padding: "20px",
        backgroundColor: "#fff",
        borderRadius: "10px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Typography
        variant="h5"
        sx={{ mb: 2, textAlign: "center", color: "#d51317" }}
      >
        {t('dto_5_form_title')}
      </Typography>
      <TextField
        name="nombre"
        label= {t('dto_5_form_name')}
        type="text"
        fullWidth
        margin="normal"
        required
        value={formData.nombre}
        onChange={handleChange}
        sx={{
          "& .MuiInputBase-root": {
            borderRadius: "8px",
          },
        }}
      />
      <TextField
        name="email"
        label= {t('dto_5_form_mail')}
        type="email"
        fullWidth
        margin="normal"
        required
        value={formData.email}
        onChange={handleChange}
        sx={{
          "& .MuiInputBase-root": {
            borderRadius: "8px",
          },
        }}
      />
      <TextField
        name="tel"
        label= {t('dto_5_form_tel')}
        type="tel"
        fullWidth
        margin="normal"
        required
        value={formData.tel}
        onChange={handleChange}
        sx={{
          "& .MuiInputBase-root": {
            borderRadius: "8px",
          },
        }}
      />
      <TextField
        name="message"
        label= {t('dto_5_form_message')}
        multiline
        rows={4}
        fullWidth
        margin="normal"
        required
        value={formData.message}
        onChange={handleChange}
        sx={{
          "& .MuiInputBase-root": {
            borderRadius: "8px",
          },
        }}
      />
      <Box sx={{ mt: 2 }}>
  {/* Checkbox Obligatorio */}
  <FormControlLabel
    control={
      <Checkbox
        name="acceptTerms"
        checked={formData.acceptTerms}
        onChange={handleChange}
        sx={{
          color: "#e63946",
          "&.Mui-checked": { color: "#e63946" },
          //padding: 0, // Elimina espacio extra
        }}
      />
    }
    label={
      <Typography
        sx={{
          fontSize: "12px", // Tamaño del texto
          display: "inline", // Evita bloqueos
          lineHeight: 1.5, // Mejor alineación
        }}
      >
         {t('dto_5_form_datos_info_check')}
      </Typography>
    }
    sx={{
      display: "flex", // Asegura que estén en línea
      alignItems: "center", // Alinea verticalmente
      gap: "8px", // Espaciado entre checkbox y texto
    }}
  />

  {/* Checkbox Opcional */}
  <FormControlLabel
    control={
      <Checkbox
        name="acceptOffers"
        checked={formData.acceptOffers}
        onChange={handleChange}
        sx={{
          color: "#e63946",
          "&.Mui-checked": { color: "#e63946" },
          //padding: 0, // Elimina espacio extra
        }}
      />
    }
    label={
      <Typography
        sx={{
          fontSize: "12px",
          display: "inline",
          lineHeight: 1.5,
        }}
      >
         {t('dto_5_form_datos_publi_check')}
      </Typography>
    }
    sx={{
      display: "flex",
      alignItems: "center",
      gap: "8px",
    }}
  />
</Box>

 
      {errorMessage && (
        <Typography
          variant="body2"
          color="error"
          sx={{ mt: 2, textAlign: "center" }}
        >
          {errorMessage}
        </Typography>
      )}
      <Button
        type="submit"
        variant="contained"
        fullWidth
        sx={{
          mt: 3,
          backgroundColor: "#d51317",
          color: "#fff",
          padding: "10px",
          borderRadius: "8px",
          "&:hover": { backgroundColor: "#d62828" },
        }}
        startIcon={<MailIcon />}
      >
         {t('dto_5_form_button')}
      </Button>
      {responseMessage && (
        <Typography
          variant="body2"
          color={responseMessage.includes("exitosamente") ? "green" : "error"}
          sx={{ mt: 2, textAlign: "center" }}
        >
          {responseMessage}
        </Typography>
      )}
    </form>
  );
};

export default ReservationForm;
